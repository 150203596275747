var IframeMessenger = require('../iframe_messenger/iframe_messenger');
var BillingInformationSubmitter = require('./billing_information_submitter');

var MarinerBillingService = function (iframeSrcUrl) {
    var iframe = createIFrame(iframeSrcUrl);
    var iframeMessenger = IframeMessenger.Outside(iframe, iframeSrcUrl, IframeMessenger.DefaultMessageEventListener());

    function createIFrame(iframeSrcUrl) {
        var newIFrame = document.createElement('iframe');
        newIFrame.setAttribute('id', 'mariner-iframe');
        newIFrame.setAttribute('hidden', true);
        newIFrame.setAttribute('height', '0');
        newIFrame.setAttribute('width', '0');
        newIFrame.setAttribute('tabindex', '-1');
        newIFrame.setAttribute('style', 'display: none;');
        newIFrame.setAttribute('src', iframeSrcUrl);
        document.body.appendChild(newIFrame);

        return newIFrame;
    }

    var billingInformationSubmitter = BillingInformationSubmitter(iframeMessenger);

    return {
        submitBillingInformation: billingInformationSubmitter.submit,
        submitSpsInformation: billingInformationSubmitter.submitSPS
    };
};

module.exports = MarinerBillingService;
