var $ = require('../jquery/jquery');
var IframeMessageSender = require('./iframe_message_sender');
var IframeMessageEnqueuer = require('./iframe_message_enqueuer');
var IframeReplyListener = require('./iframe_reply_listener');
var serialize = require('serialize-javascript');
var IframeMessenger = {};

IframeMessenger.Outside = function (iframeEl, targetOrigin, messageListener) {
    var deferredsById = {};
    var iframeMessageSender = IframeMessageSender(iframeEl, targetOrigin);
    var iframeMessageEnqueuer = IframeMessageEnqueuer(iframeEl, iframeMessageSender);
    var iframeReplyListener = IframeReplyListener(messageListener, targetOrigin, deferredsById);

    iframeReplyListener.listen();

    return {
        send: function (message) {
            var id = generateId();

            iframeMessageEnqueuer.enqueue(id, message);

            var deferred = $.Deferred();
            deferredsById[id] = deferred;
            return deferred.promise();
        },
        destroy: iframeReplyListener.destroy
    };

    function generateId() {
        var id = Math.floor(Date.now() + 10000000000000 * Math.random()).toString(16);

        while (deferredsById.hasOwnProperty(id)) {
            id = generateId();
        }

        return id;
    }
};

IframeMessenger.Inside = function (messageListener) {
    return {
        listen: function (listener) {
            messageListener.subscribe(function (event) {
                var eventData = JSON.parse(event.data);

                listener(eventData.body)
                    .then(function (res) {
                        var response = {};

                        if (res) {
                            response = JSON.parse(res);
                        }

                        replySuccess(eventData.id, response);
                    },
                    function () {
                        replyFailure(eventData.id);
                    })
            });
        },
        destroy: function () {
            messageListener.unsubscribeAll();
        }
    };

    function replySuccess(id, response) {
        var data = { id: id, status: 'success', response: response };
        window.parent.postMessage(serialize(data, { isJSON: true }), "*");
    }

    function replyFailure(id) {
        var data = { id: id, status: 'failure' };
        window.parent.postMessage(serialize(data, { isJSON: true }), "*");
    }
};

IframeMessenger.DefaultMessageEventListener = function () {
    var listeners = [];

    var listenersCaller = function (jQueryEvent) {
        var event = jQueryEvent.originalEvent;
        listeners.forEach(function (listener) {
            listener(event);
        });
    };

    $(window).on('message', listenersCaller);

    return {
        subscribe: function (fn) {
            listeners.push(fn);
        },
        unsubscribeAll: function () {
            $(window).off('message', listenersCaller);
        }
    }
};

module.exports = IframeMessenger;
