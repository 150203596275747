var $ = require('../jquery/jquery');
var serialize = require('serialize-javascript');
IframeMessageSender = function (iframeEl, targetOrigin) {
    return {
        send: function (id, message) {
            var data = { id: id, body: message };
            iframeEl.contentWindow.postMessage(serialize(data, { isJSON: true }), targetOrigin);
        }
    };
};

module.exports = IframeMessageSender;