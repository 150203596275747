var $ = require('../jquery/jquery');
IframeMessageEnqueuer = function (iframeEl, iframeMessageSender) {
    var iframeLoaded = false;
    var enqueuedMessages = [];

    $(iframeEl).on("load", function(){
        iframeLoaded = true;
        $.each(enqueuedMessages, function(_, enqueuedMessage){
            var message = enqueuedMessage.message;
            var id = enqueuedMessage.id;

            iframeMessageSender.send(id, message);
        })
    });

    return {
        enqueue: function (id, message) {
            if (iframeLoaded) {
                iframeMessageSender.send(id, message);
            } else {
                enqueuedMessages.push({id: id, message: message});
            }
        }
    };
};

module.exports = IframeMessageEnqueuer;
