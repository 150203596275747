var serialize = require('serialize-javascript');
var BillingInformationSubmitter = function (iframeMessenger) {
    return {
        submit: function (creditCardNumber, clientId, passthroughData) {
            var data = {
                requestData: {
                    creditCardNumber: creditCardNumber,
                    clientId: clientId,
                    passthroughData: passthroughData
                }
            };
            var message = serialize(data, { isJSON: true });
            return iframeMessenger.send(message);
        },
        submitSPS: function (creditCardNumber, namespaceId, passthroughData, authToken) {
            var data = {
                requestData: {
                    creditCardNumber: creditCardNumber,
                    namespaceId: namespaceId,
                    passthroughData: passthroughData
                },
                authToken: authToken
            };
            var message = serialize(data, { isJSON: true });
            return iframeMessenger.send(message);
        }
    };
};

module.exports = BillingInformationSubmitter;
