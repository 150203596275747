var $ = require('../jquery/jquery');;

IframeReplyListener = function (messageListener, targetOrigin, deferredsById) {
    function replyListener(event) {
        if (event.origin != targetOrigin) {
            return;
        }

        var data = JSON.parse(event.data);
        var deferred = deferredsById[data.id];
        var response = data.response;

        var status = data.status;
        if (deferred != null) {
            if (status == "success") {
                deferred.resolve(response);
            } else if (status == "failure") {
                deferred.reject();
            }
        }
    }

    return {
        listen: function () {
            messageListener.subscribe(replyListener);
        },
        destroy: function () {
            messageListener.unsubscribeAll()
        }
    }
};

module.exports = IframeReplyListener;
